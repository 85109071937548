
.resizable-box {
    position: relative;
    /* Áp dụng animation */
}
.hover-image {
    display: none;
    position: absolute;
    top: 0;
    left: 90%;
    width: 100px;
    height: 100px;
    z-index: 2;
    border-radius: 15%;
}

.resizable-box:hover .hover-image {
    display: block;
}

.arrowIcon{
    width: 43px;
    height: 30px;
}

.fly-sky{
    width: 200px;
    height: 200px;
}
@media (max-width: 932px) and (orientation: landscape) {
    .arrowIcon{
        width: 23px;
        height: 10px;
    }
    .fly-sky{
        width: 50px;
        height: 50px;
    }
}