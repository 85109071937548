.chat {
    position: absolute;
    bottom: 115px;
    right: 15px;
    width: 438px;
    height: 570.2px;
    background-color: #FFFFFF;
    border-radius: 2%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 999999;
    transition: all 0.3s;
    user-select: none;
}
 /*---------------Header--------------------*/
.header {
    height: 13%;
    width: 100%;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

}
.logoAI{
    display: flex;
    gap: 20px;
}

.logoAI p {
    font-weight: bold;
}

.newChat{
    font-size: 25px;
    padding-right: 7px;
    cursor: pointer;
}

/*-----------------content --------- */

.content {
    height: 82%;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;
}

.message-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3px;
    overflow-y: auto;
    padding-right: 10px;
    max-height: 400px;
}

.message-container::-webkit-scrollbar {
    width: 6px;
}

.message-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.message-container::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
}

.message-container::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

.message {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    max-width: 80%;
}

.bot-message {
    align-self: flex-start;
}

.bot-message img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.user-message {
    align-self: flex-end;
}

.message-text {
    padding: 10px 15px;
    border-radius: 15px;
    background: #f0f0f0;
    font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    font-weight: bold;
    cursor: text;
    user-select: text;
    max-width: 286.4px;
    min-width: 50px;
    width: fit-content;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.user-message .message-text {
    background: #C9021A;
    color: white;
    font-weight: 500;
}

.suggestion-buttons {
    display: flex;
    gap: 10px;
    margin: 15px 0;
    overflow-x: auto;
    padding: 5px;
    -webkit-overflow-scrolling: touch;
}

.suggestion-buttons::-webkit-scrollbar {
    height: 6px;
}

.suggestion-buttons::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.suggestion-buttons::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
}

.suggestion-buttons::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

.suggestion-buttons button {
    white-space: nowrap;
    padding: 8px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    background: white;
    cursor: pointer;
    flex-shrink: 0;
}

.suggestion-buttons button:hover {
    background: #f5f5f5;
}

.input-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
}

.input-container textarea {
    flex: 1;
    padding: 10px;
    outline: none;
    border: none;
    resize: none;
    min-height: 20px;
    max-height: 100px;
    overflow-y: auto;
    line-height: 1.5;
    font-family: inherit;
    word-wrap: break-word;
}

.input-container textarea::-webkit-scrollbar {
    width: 6px;
}

.input-container textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.input-container textarea::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
}

.send-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    padding: 5px;
}

.send-button:hover {
    color: #000;
}

/* ------------- footer -------------- */

.footer {
    height: 5%;
    width: 100%;
    border-bottom-left-radius: 2%;
    border-bottom-right-radius: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer p{
    font-size: 11px;
}

.backDrop{
    position: absolute;
    right: 50px;
    bottom: 190px;
    transition: opacity 0.3s ease;
    animation: fadeIn 0.3s ease;
}
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}