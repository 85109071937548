.loadingScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loadingScreen_board {
    background: rgba(255, 255, 255, 0.8); /* Slightly transparent background to show the image */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  