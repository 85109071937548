.zoom-controls {
    position: absolute;
    bottom: 50px;
    left: 10px;
    width: 70px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999999;
    justify-content: space-between;
}

.zoom-button {
    background-color: rgba(243, 113, 37, 0.9);
    height: 40px;
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
}

.zoom-slider-container {
    background-color: rgba(243, 113, 37, 0.9);
    height: 80px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoom-slider {
    width: 30px;
    color: white;
}

@media (max-width: 932px) and (orientation: landscape) {
    .zoom-controls {
        bottom: 50px;
        left: 5px;
        width: 40px;
        height: 90px;
    }

    .zoom-button {
        height: 30px;
        width: 30px;
    }

    .zoom-slider-container {
        height: 60px;
        width: 30px;
    }

    .zoom-slider {
        width: 20px;
    }
}

.floating-circle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #1890ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    z-index: 1000;
    transition: all 0.3s;
}

.floating-circle span {
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.floating-circle:hover {
    transform: scale(1.1);
    background-color: #40a9ff;
}

.chatBox {
    position: absolute;
    bottom: 45px;
    right: 15px;
    width: 60px;
    height: 60px;
    background-color: black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    z-index: 1000;
    transition: all 0.3s;
}

.chatBox:hover {
    transform: scale(1.1);
    background-color: #333;
}

.chat-icon {
    width: 30px;
    height: 30px;
    filter: invert(1); /* Đổi màu icon thành trắng */
}

iframe{
    position: absolute;
    bottom: 115px;
    right: 15px;
    width: 438px;
    height: 570.2px;
    background-color: rgb(241, 242, 243);
    border-radius: 2%;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 999999;
    transition: all 0.3s;
}

