.resizable-box {
   position: relative;
    /* Áp dụng animation */
}
.local{
    animation: scaleUpDown 0.5s infinite alternate;
    width: 40px;
    height: 40px;
}

@media(max-width: 930px){
  .local{
    width: 20px;
    height: 20px;
  }
}

@keyframes scaleUpDown {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.5);
    }
}

