/* modal about  */
.custom-modal {
	position: fixed;
	top: 10%;
	right: 0;
	width: 430px;
	padding: 20px;
	max-width: 100%;
	background-color: #fa9627;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	z-index: 999999999;
	opacity: 0.9;
	animation: slide-start 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.custom-modal-enter {
	opacity: 1;
	transform: translate(-50%, -50%);
}

.custom-modal-exit {
	opacity: 0;
	transform: translate(-50%, -40%);
}

.scrollable-content {
	max-height: 200px;
	/* Adjust the height as needed */
	overflow-y: auto;
}
.scrollable-content::-webkit-scrollbar{
	display: none;
}

.scrollable-content{
	-ms-overflow-style: none; /* IE và Edge */
      scrollbar-width: none; /* Firefox */
}

@keyframes slide-start {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
	}
}

.custom-modal-exit {
	animation: slide-end 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	transform: translateX(100%);
}

@media (max-width: 932px) {
	.custom-modal {
		width: 35%;
		height: 43%;
		padding: 15px;
		margin-top: 5%;
		overflow-y: 'scroll';
	}

	.custom-modal h3 {
		font-size: 13px;
	}

	.custom-modal p {
		font-size: 11px;
	}

	.custom-modal button {
		width: 22px;
		height: 32px;
	}

	.scrollable-content {
		max-height: 60%;
		/* Adjust the height as needed */
	}
}

/* Map */
.ggMap .container {
	position: absolute;
	top: 0;
	width: 100%;
	/* Adjusted width for a slightly wider map */
	max-width: 1100px;
	/* Increased max width */
	background-color: white;
	height: 700px;
	/* Increased height */
	border-top-right-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 10px;
}

.close-button {
	align-self: flex-end;
	margin-bottom: 10px;
}

.map-iframe {
	width: 100%;
	height: calc(100% - 50px);
	/* Adjusting height considering the button height */
	border: 0;
}

/* Media Query for Mobile View */
@media (max-width: 768px) {
	.container {
		width: 95%;
		/* Adjust the width for mobile view */
		height: 50vh;
		/* Adjust the height for mobile view */
		padding: 5px;
	}

	.close-button {
		align-self: flex-start;
		background-color: #fa9627;
	}

	.map-iframe {
		height: calc(100% - 40px);
		/* Adjusting height considering the button height */
	}
}

@media (max-width: 768px) {
	.image-grid-container {
		width: 90%;
		height: 90%;
		padding: 10px;
	}

	.image-grid {
		grid-template-columns: repeat(2, 1fr);
		gap: 5px;
	}
}

/* Image */

.ImageContainer {
    overflow-y: auto;
    white-space: nowrap;
    padding: 10px;
    background-color: #f0f0f0; /* Màu nền */
}

.closeButton {
	cursor: pointer;
	z-index: 10001;
	min-width: 40px;
	height: 40px;
	background-color: red;
	float: right;
}
.gridImage {
    display: inline-block;
}


.imgContainer {
    display: inline-block;
    margin-right: 10px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: border-color 0.3s;
}

.imgContainer:hover {
    border-color: red; /* Màu viền khi hover */
}
.img {
    width: 150px; /* Chiều rộng của hình ảnh */
    height: auto;
    border-radius: 5px; /* Bo góc */
	cursor: pointer;
}

@media (max-width: 932px) {
	.ImageContainer {
		width: 80%;
		height: 71%;
		margin-top: 10px;
	}
}