.ggMap{
    width: 1200;
    height: auto;
}
.container{
    width: 1200;
    position: absolute; 
    top: 0;
    background-color: white; 
    z-Index: 99999;
}