.items-container {
    width: 110%;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease-in-out;
}

.items-container.show {
    max-height: 500px;
    /* Adjust based on the number of items and their heights */
}

.item {
    background-color: rgba(243, 113, 37, 0.9);
    list-style: none;
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: aliceblue;
    border-bottom: 1px solid white;
    /* Optional: add a separator between items */
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.item:hover {
    background-color: #b07527;
}

.item.show {
    opacity: 1;
    transform: translateY(0);
}

.menu {
    display: flex;
    position: absolute;
    left: 45px;
    animation-duration: 0.5s;
}

.slide-in {
    animation-name: slideIn;
}

.slide-out {
    animation-name: slideOut;
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.menuLeft {
    width: 40px;
    height: 40px;
}

.header-button {
    height: 40px;
}

.closeMenu {
    width: 40px;
    height: 40px;
}

@media (max-width: 930px) {
    .menuLeft {
        width: 30px;
        height: 30px;
    }

    .header-button {
        height: 30px;
    }

    .closeMenu {
        width: 30px;
        height: 30px;
    }
}