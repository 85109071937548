.arrowIcon{
    width: 43px;
    height: 30px;
}
.fly-sky{
    width: 200px;
    height: 200px;
}
@media (max-width: 932px) and (orientation: landscape) {
    .arrowIcon{
        width: 23px;
        height: 10px;
    }
      .fly-sky{
        width: 50px;
        height: 50px;
    }
}

.transition-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;
    z-index: 1000;
  }
  
  .transition-overlay.active {
    opacity: 1;
  }
